import Response from "./response.js";
import StorageService from "./storage-service.js";

class FetchService extends StorageService {

    async fetchResponse(method, headers, body, blob, route, validate = true) {
        const validado = validate ? await this.validarToken() : true;
        if (validado) {
            let requestOptions = {
                method: method,
                headers: headers
            };

            if(body)
                requestOptions.body = JSON.stringify(body);
            
            if(blob)
                requestOptions.responseType = "blob";
            
            let response = new Response();
            try{
                const fetchResponse = await fetch(route, requestOptions);
                const data = blob ? await fetchResponse.blob() : await fetchResponse.json();

                response.statusCode = fetchResponse.status;
                response.data = data;
            }
            catch{
                response.statusCode = 400;
                response.data = {
                    mensagem: "Unavailable service. Try again later or contact your system administrator."
                };
            }
            return response;
        }
    }
}

export default FetchService;