import router from '../router/index';

class StorageService {
    async validarToken() {
        const login = localStorage.getItem("login");
        if(!login){
            localStorage.setItem("rotaNavegacao", router.currentRoute.fullPath);
            if(router.currentRoute.fullPath.includes("/admin"))
                router.push("/admin/login").catch(failure => { localStorage.setItem("erro", failure) });
            else
                router.push("/").catch(failure => { localStorage.setItem("erro", failure) });
            
            return false;
        }
        else{
            const admin = JSON.parse(login).admin;
            if(admin && !router.currentRoute.fullPath.includes("/admin")){
                router.push("/").catch(failure => { localStorage.setItem("erro", failure) });
                return false;
            }
            else if(!admin && router.currentRoute.fullPath.includes("/admin")){
                router.push("/admin/login").catch(failure => { localStorage.setItem("erro", failure) });
                return false;
            }
        }

        this.flagPermissao = false;

        const usuario = JSON.parse(login)

        if(router.currentRoute.fullPath.includes("/home") || router.currentRoute.fullPath.includes("/admin/home")){
            return true;
        }

        usuario.paginas.forEach(m => {
            if(m.url == router.currentRoute.path)
                this.flagPermissao = true;
        });

        if(this.flagPermissao == false){
            localStorage.removeItem("rotaNavegacao");
            router.push("/nao-autorizado").catch(failure => { localStorage.setItem("erro", failure) });
            return false;
        }

        return true;
    }
}

export default StorageService;