<template>
  <div class="loading-bg">
    <fingerprint-spinner
      :animation-duration="1500"
      :size="64"
      color="#d2a749"
    />
  </div>
</template>
<script>
import { FingerprintSpinner } from 'epic-spinners'
export default {
  components: {
    FingerprintSpinner,
  },
};
</script>
<style scoped>
.loading-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>